// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout} from 'antd';

// Store
import * as sessionSelectors from '../../store/redux/selectors/sessionSelectors';

// Components
import Login from '../session/Login';
import LoginView from "../session/sessionViews/Login";
import Register from "../session/sessionViews/Register";
import ForgotPassword from "../session/sessionViews/ForgotPassword";
import ResetPassword from "../session/sessionViews/ResetPassword";
import Users from '../users/Users';
import Remissions from '../remission/Remissions';
import CTN from '../ctn/CTN';
import Tariffs from '../tariffs/Tariffs';
import Thresholds from '../thresholds/Thresholds';
import Inbounds from '../inbounds/Inbounds';
import Alliances from '../alliances/Alliances';
import Stores from "../stores/Stores";
import PackageSizes from "../packageSizes/PackageSizes";
import Orders from "../orders/Orders";
import Locations from "../locations/Locations";
import Planning from "../planning/Planning";
import Suppliers from '../suppliers/Suppliers';
import CarrierTabs from "../carriers/CarrierTabs";
import StoreMapTabs from "../storeMaps/StoreMapTabs";

// Utils
import {routes} from '../../utils/routes';
import {ROLES} from '../../utils/constants';
import {getLocalStorageItem} from '../../utils/functions';

const {Content} = Layout;


function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const renderRole = getLocalStorageItem('renderRole');
  const allowedModules = ROLES[renderRole as keyof typeof ROLES]?.allowedModules.map(module => module.name);

  return (
    <Content className={`flex flex-col w-0 flex-1 overflow-hidden min-h-screen ${isLoggedIn ? 'py-6 px-8' : ''}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            <Route path={routes.home} element={'Bienvenido al portal de logística'} />

            {allowedModules.includes(routes.remissions) &&
              <Route path={routes.remissions} element={<Remissions />} />}
            {allowedModules.includes(routes.ctn) &&
              <Route path={routes.ctn} element={<CTN />} />}
            {allowedModules.includes(routes.tariff) &&
              <Route path={routes.tariff} element={<Tariffs />} />}
            {allowedModules.includes(routes.thresholds) &&
              <Route path={routes.thresholds} element={<Thresholds />} />}
            {allowedModules.includes(routes.inbound) &&
              <Route path={routes.inbound} element={<Inbounds />} />}
            {allowedModules.includes(routes.shipping) &&
              <Route path={routes.shipping} element={'Envíos'} />}
            {allowedModules.includes(routes.alliances) &&
              <Route path={routes.alliances} element={<Alliances />} />}
            {allowedModules.includes(routes.stores) &&
              <Route path={routes.stores} element={<Stores />} />}
            {allowedModules.includes(routes.packageSize) &&
              <Route path={routes.packageSize} element={<PackageSizes />} />}
            {allowedModules.includes(routes.orders) &&
              <Route path={routes.orders} element={<Orders />} />}
            {allowedModules.includes(routes.locations) &&
              <Route path={routes.locations} element={<Locations />} />}
            {allowedModules.includes(routes.planning) &&
              <Route path={routes.planning} element={<Planning />} />}
            {allowedModules.includes(routes.suppliers) &&
              <Route path={routes.suppliers} element={<Suppliers />} />}
            {allowedModules.includes(routes.users) &&
              <Route path={routes.users} element={<Users />} />}
            {allowedModules.includes(routes.transport) &&
              <Route path={routes.transport} element={'Servicios'} />}
            {allowedModules.includes(routes.carriers) &&
              <Route path={routes.carriers} element={<CarrierTabs />} />}
            {allowedModules.includes(routes.storeMaps) &&
              <Route path={routes.storeMaps} element={<StoreMapTabs />} />}

            <Route path={'*'}
                   element={<Navigate to={allowedModules[0]} />} />
          </>
          :
          <>
            <Route path={routes.authentication} element={<Login />} />
            <Route path={routes.login} element={<Login />} />
            
            <Route path={routes.supplierAccount.login} element={<LoginView />} />
            <Route path={routes.supplierAccount.register} element={<Register />} />
            <Route path={routes.supplierAccount.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.supplierAccount.resetPassword} element={<ResetPassword />} />

            <Route path={routes.carrierAccount.login} element={<LoginView />} />
            <Route path={routes.carrierAccount.register} element={<Register />} />
            <Route path={routes.carrierAccount.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.carrierAccount.resetPassword} element={<ResetPassword />} />

            <Route path={'*'} element={<Navigate to={routes.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;