import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Col, Layout, Menu} from 'antd';
import {
  TeamOutlined,
  LogoutOutlined,
  StockOutlined,
  ImportOutlined,
  BarChartOutlined,
  WalletOutlined,
  ShopOutlined,
  BlockOutlined,
  SnippetsOutlined,
  ColumnWidthOutlined,
  AppstoreOutlined,
  AimOutlined,
  CalendarOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  CarOutlined,
  BankOutlined
} from '@ant-design/icons';

// Store
import * as generalSelectors from '../../store/redux/selectors/generalSelectors';
import {setCurrentRoute} from '../../store/redux/actions/generalActions';
import {useAppDispatch} from '../../store/store';

// Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';

// Constants and Functions
import {routes} from '../../utils/routes';
import {ROLES} from '../../utils/constants';
import {clearStorage, getLocalStorageItem, getWindowInformation, reloadWindow} from '../../utils/functions';
const {Sider} = Layout;
import logo from '../../assets/DKT_Lockup_Primary_White_RGB.png';


const Navbar = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRoute = useSelector(generalSelectors.currentRouteSelector) ?? routes.home;
  const userName = getLocalStorageItem('userName');
  const roleName = getLocalStorageItem('roleName');
  const renderRole = getLocalStorageItem('renderRole')!;
  const allowedModules = ROLES[renderRole as keyof typeof ROLES].allowedModules.map(module => module.name);

  const setNavItems = () => {
    const navItems = [];

    allowedModules.includes(routes.remissions) &&
      navItems.push({ key: routes.remissions, icon: <SnippetsOutlined />, label: 'Remisiones' });
    allowedModules.includes(routes.ctn) &&
      navItems.push({ key: routes.ctn, icon: <WalletOutlined />, label: 'CTN' });
    allowedModules.includes(routes.tariff) &&
      navItems.push({ key: routes.tariff, icon: <BarChartOutlined />, label: 'Tarifas' });
    allowedModules.includes(routes.thresholds) &&
      navItems.push({ key: routes.thresholds, icon: <StockOutlined />, label: 'Umbrales' });
    allowedModules.includes(routes.inbound) &&
      navItems.push({ key: routes.inbound, icon: <ImportOutlined />, label: 'Inbound' });
    /* allowedModules.includes(routes.shipping) &&
      navItems.push({ key: routes.shipping, icon: <SendOutlined />, label: 'Envíos' }); */
    allowedModules.includes(routes.alliances) &&
      navItems.push({ key: routes.alliances, icon: <BlockOutlined />, label: 'Alianzas' });
    allowedModules.includes(routes.stores) &&
      navItems.push({ key: routes.stores, icon: <BankOutlined />, label: 'Centros' });
    allowedModules.includes(routes.packageSize) &&
      navItems.push({ key: routes.packageSize, icon: <ColumnWidthOutlined />, label: 'Dimensiones' });
    allowedModules.includes(routes.orders) &&
      navItems.push({ key: routes.orders, icon: <AppstoreOutlined />, label: 'Órdenes' });
    allowedModules.includes(routes.locations) &&
      navItems.push({ key: routes.locations, icon: <AimOutlined />, label: 'Ubicaciones' });
    allowedModules.includes(routes.locations) &&
      navItems.push({ key: routes.planning, icon: <CalendarOutlined />, label: 'Planeación' });
    allowedModules.includes(routes.suppliers) &&
      navItems.push({ key: routes.suppliers, icon: <SolutionOutlined />, label: 'Proveedores' });
    allowedModules.includes(routes.carriers) &&
      navItems.push({ key: routes.carriers, icon: <CarOutlined />, label: 'Transportistas' });
    allowedModules.includes(routes.transport) &&
      navItems.push({ key: routes.transport, icon: <ApartmentOutlined />, label: 'Servicios' });
    allowedModules.includes(routes.storeMaps) &&
      navItems.push({ key: routes.storeMaps, icon: <ShopOutlined />, label: 'Tiendas' });
    allowedModules.includes(routes.users) &&
    navItems.push({ key: routes.users, icon: <TeamOutlined />, label: 'Usuarios' });

    return navItems;
  };

  const navItems = setNavItems();

  useEffect(() => {
    const { pathname } = getWindowInformation();
    dispatch(setCurrentRoute(pathname));
  }, []);

  const handleRouteSelector = (route: string) => {
    dispatch(setCurrentRoute(route));
    navigate(route, { replace: false });
  };
  
  const handleLogout = () => {
    dispatch(setCurrentRoute(routes.home));
    clearStorage();
    reloadWindow();
  };

  return (
    <Sider breakpoint="md"
           className={'h-screen min-h-screen bg-grey-dkt-800 border-r md:flex md:flex-shrink-0 sticky bottom-0 top-0 [&>div]:w-full'}
           onBreakpoint={broken => {
             setCollapsed(broken);
           }}
    >
      <Col className={'h-[80vh] w-full flex flex-col pb-4 overflow-x-hidden overflow-y-auto'}>
        <Col className="logo p-2 h-[15%] flex">
          <img className="w-full my-auto"
               src={logo} />
        </Col>
        <Menu mode="inline" selectedKeys={[currentRoute]} items={navItems}
              className={' h-[85%] border-0 bg-grey-dkt-800 text-grey-dkt-300 [&>li]:px-4 ' +
                '[&>.ant-menu-item-selected]:bg-grey-dkt-800 [&>.ant-menu-item-selected]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):hover]:bg-grey-dkt-700 [&>li:hover]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):active]:bg-grey-dkt-800 [&>li:after]:border-r-0 ' +
                '[&>.ant-menu-item-selected:after]:border-0 ' +
                `${isCollapsed ? '[&>li>.anticon]:ml-4 ' : '[&>li>.anticon]:ml-0 '}`
              }
              onClick={(item) => handleRouteSelector(item.key)}
        />
      </Col>
      <Col className={'flex h-[20vh] border-t border-grey-dkt-600 p-4 bg-red bottom-0 absolute w-full'}>
        <Col className={'flex-shrink-0 w-full'}>
          {!isCollapsed &&
            <Col className={'group'}>
              <p className={'text-sm font-medium text-grey-dkt-300 group-hover:text-grey-dkt-200'}>
                {userName}
              </p>
              <p className={'text-sm text-grey-dkt-400 group-hover:text-grey-dkt-200'}>
                {roleName}
              </p>
            </Col>
          }
          <ButtonComponent text={isCollapsed ? '' : 'Cerrar sesión'}
                           icon={<LogoutOutlined />}
                           className={'w-full h-10 text-white bg-orange-dkt-400 hover:bg-grey-dkt-700 ' +
                             `${isCollapsed ? 'mt-0 ' : 'mt-4 '}`
                           }
                           onClick={handleLogout} />
        </Col>
      </Col>
    </Sider>
  );
}

export default Navbar;