/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* --------------- Remission ---------------*/
export const remissionTypes = {
  GET_ALL_REMISSIONS: 'GET_ALL_REMISSIONS',
};

/* --------------- Session ---------------*/
export const sessionTypes = {
  LOGGED_IN: 'LOGGED_IN',
};

/* --------------- CTN ---------------*/
export const ctnTypes = {
  GET_ALL_CTN: 'GET_ALL_CTN',
};
/* --------------- Tariff ---------------*/
export const tariffTypes = {
  GET_ALL_TARIFFS: 'GET_ALL_TARIFFS',
};

/* --------------- Thresholds ---------------*/
export const thresholdTypes = {
  GET_ALL_THRESHOLDS: 'GET_ALL_THRESHOLDS',
};

/* --------------- Inbounds ---------------*/
export const inboundTypes = {
  GET_ALL_INBOUNDS: 'GET_ALL_INBOUNDS',
};

/* --------------- User ---------------*/
export const userTypes = {
  GET_USER_PARAMS: 'GET_USER_PARAMS',
  GET_ALL_USERS: 'GET_ALL_USERS',
};

/* --------------- Alliances ---------------*/
export const allianceTypes = {
  GET_ALLIANCE_PARAMS: 'GET_ALLIANCE_PARAMS',
  GET_ALL_ALLIANCES: 'GET_ALL_ALLIANCES',
};

/* --------------- Stores ---------------*/
export const storeTypes = {
  GET_STORE_PARAMS: 'GET_STORE_PARAMS',
  GET_ALL_STORES: 'GET_ALL_STORES',
};

/* --------------- Package Sizes ---------------*/
export const packageSizeTypes = {
  GET_ALL_PACKAGE_SIZES: 'GET_ALL_PACKAGE_SIZES',
};

/* --------------- Orders ---------------*/
export const orderTypes = {
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
};

/* --------------- Locations ---------------*/
export const locationTypes = {
  GET_ALL_LOCATIONS: 'GET_ALL_LOCATIONS',
};

/* --------------- Planner ---------------*/
export const plannerTypes = {
  GET_PLANNER_PARAMS: 'GET_PLANNER_PARAMS',
  GET_ALL_PLANNER: 'GET_ALL_PLANNER',
};

/* --------------- Suppliers ---------------*/
export const supplierTypes = {
  GET_ALL_SUPPLIERS: 'GET_ALL_SUPPLIERS',
}

/* --------------- Carrier ---------------*/
export const carrierTypes = {
  GET_ALL_CARRIERS: 'GET_ALL_CARRIERS',
};

/* --------------- Carrier Users ---------------*/
export const carrierUserTypes = {
  GET_CARRIER_USER_PARAMS: 'GET_CARRIER_USER_PARAMS',
  GET_ALL_CARRIER_USERS: 'GET_ALL_CARRIER_USERS',
};

/* --------------- Store Maps ---------------*/
export const storeMapTypes = {
  GET_ALL_STORE_MAPS: 'GET_ALL_STORE_MAPS',
};

/* --------------- User Store Maps ---------------*/
export const userStoreMapTypes = {
  GET_USER_STORE_MAPS_PARAMS: 'GET_USER_STORE_MAPS_PARAMS',
  GET_ALL_USER_STORE_MAPS: 'GET_ALL_USER_STORE_MAPS',
};
