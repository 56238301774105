import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {UserStoreMapType} from "../types/userStoreMapTypes";


function getParams() {
  return request({
    url: 'userStoreMap/getParams',
    method: 'GET',
  });
}
function getAll(data: PaginationType) {
  return request({
    url: 'userStoreMap/getAll',
    method: 'GET',
    params: data
  });
}
function update(id: string, userStoreMap: UserStoreMapType) {
  return request({
    url: `userStoreMap/update/${id}`,
    method: 'PATCH',
    data: userStoreMap
  });
}

export default {
  getParams,
  getAll,
  update,
}