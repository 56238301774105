// Libraries
import React, {useEffect, useState} from 'react';
import {Row, Form, Modal, DatePicker, Switch, Col, Popover, Divider, Tooltip, Button, Popconfirm} from 'antd';
import ColorPicker from "react-pick-color";
import moment from "moment";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from '../../store/store';
import { create, update, remove } from '../../store/redux/actions/plannerActions';
import * as plannerSelectors from "../../store/redux/selectors/plannerSelectors";

// Types
import {PlannerType} from "../../types/plannerTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import SelectComponent from "../subComponents/SelectComponent";

// Utils
import {DATETIME_FORMAT, FULL_DATETIME_FORMAT} from "../../utils/constants";


type PropsType = {
  isEditing?: boolean;
  plannerToEdit?: PlannerType;
  onToggleModal: (visible: boolean) => void;
};
const PlanningFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const { isEditing, plannerToEdit, onToggleModal } = props;
  const dispatch = useAppDispatch();

  const [pickColorOpen, setPickColorOpen] = useState<boolean>(false);
  const [preselectedColor, setPreselectedColor] = useState<string>('#000000');
  const [selectedColor, setSelectedColor] = useState<string>(isEditing ? plannerToEdit!.hexColor : '#E6EAFC');
  const [defaultBackgroundColors, setDefaultBackgroundColors] = useState<string[]>([
      '#E6EAFC', '#FFF3DC', '#FFD5D9', '#DCFFE5', '#FFE0C3', '#DBCCEF', '#FCFFB1', '#E7E7E7'
    ]
  );

  const { stores } = useSelector(plannerSelectors.plannerParamsSelector);

  const colorPickerContent = (
    <Col className={'text-right'}>
      <ColorPicker color={selectedColor}
                   hideAlpha={true}
                   onChange={(color) => setPreselectedColor(color.hex)} />

      <ButtonComponent text={'Aceptar'}
                       className={'ant-btn-primary mt-2'}
                       onClick={() => {
                         setDefaultBackgroundColors([...defaultBackgroundColors, preselectedColor]);
                         setPickColorOpen(false);
                       }} />
    </Col>
  );

  useEffect(() => {
    if(isEditing && plannerToEdit) {
      form.setFieldsValue({
        withReturn: plannerToEdit.withReturn,
        dateTime: moment(plannerToEdit.startDatetime),
        unloadsInfo: plannerToEdit.unloadsInfo.map((unload) => ({
          storeId: unload.storeId,
          dateTime: moment(unload.endDatetime),
        })),
      });
    }
  }, [isEditing, plannerToEdit]);

  const handleSubmit = (data: PlannerType) => {
    const unloadsInfo = data.unloadsInfo.map((unload) => ({
      storeId: unload.storeId,
      endDatetime: moment(unload.dateTime).format('YYYY-MM-DDTHH:mm'),
    }));
    const formData: PlannerType = {
      withReturn: data.withReturn ?? false,
      startDatetime: moment(data.dateTime).format('YYYY-MM-DDTHH:mm'),
      hexColor: selectedColor,
      unloadsInfo,
    };
    const [month, year] = [moment(formData.startDatetime).month() + 1, moment(data.startDatetime).year()];
    data.dateTime = undefined;

    if(isEditing){
      formData.plannerId = plannerToEdit?.plannerId;
      dispatch(update(formData, month, year));
    } else {
      dispatch(create(formData, month, year));
    }

    onToggleModal(false);
  }

  return (
    <Modal title={
             <Col className={'grid grid-cols-2'}>
               <span>{isEditing ? 'Editar' : 'Crear'} planeación</span>
               <span className={'text-right'}>
                 {isEditing && plannerToEdit && (
                   <Popconfirm okText={'Confirmar'}
                               title={'¿Confirmas que deseas eliminar este evento?'}
                               onConfirm={() => {
                                 dispatch(
                                   remove(
                                     plannerToEdit!.plannerId!,
                                     moment(plannerToEdit!.startDatetime).month() + 1,
                                     moment(plannerToEdit!.startDatetime).year(),
                                   )
                                 );
                                 onToggleModal(false);
                               }}>
                     <p className={'text-red-dkt-400 cursor-pointer hover:underline'}><DeleteOutlined /> Eliminar</p>
                   </Popconfirm>
                 )}
               </span>
             </Col>
           }
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'withReturn'}
                             className={'col-span-2'}
                             label={''}
                             required={false}
                             valuePropName={'checked'}
                             child={<Switch checkedChildren={"Con retorno"}
                                            unCheckedChildren={"Sin retorno"} />}
          />
          <FormItemComponent name={'dateTime'}
                             label={'Fecha y hora'}
                             className={'col-span-2'}
                             required={true}
                             child={<DatePicker showTime={{ format: 'HH:mm' }}
                                                className={'w-full'}
                                                format={FULL_DATETIME_FORMAT} />}
          />
          <FormItemComponent name={'hexColor'}
                             label={'Color'}
                             required={false}
                             className={'col-span-2'}
                             child={
                               <Col className={'grid grid-cols-8 w-full gap-y-2'}>
                                 {defaultBackgroundColors.map((color) =>
                                   <Button key={color}
                                           style={{ backgroundColor: color }}
                                           onClick={() => setSelectedColor(color)}
                                           className={`visible rounded-[50%] h-[35px] w-[35px] 
                                             ${color === selectedColor && 'border-black'}`
                                           } />
                                 )}

                                 {isEditing && !defaultBackgroundColors.includes(plannerToEdit!.hexColor) &&
                                   <Button style={{ backgroundColor: plannerToEdit!.hexColor }}
                                           onClick={() => setSelectedColor(plannerToEdit!.hexColor)}
                                           className={`visible rounded-[50%] h-[35px] w-[35px] 
                                             ${plannerToEdit?.hexColor.includes(selectedColor) && 'border-grey-dkt-600'}`
                                           } />
                                 }


                                 <Popover content={colorPickerContent}
                                          open={pickColorOpen}
                                          trigger={"click"}
                                          placement={'right'}>
                                   <ButtonComponent text={''}
                                                    icon={<PlusOutlined />}
                                                    onClick={() => setPickColorOpen(true)}
                                                    className={'text-grey-dkt-400 rounded-[50%] h-[35px] w-[35px]'} />
                                 </Popover>
                               </Col>
                             }
          />
          <Col className={'col-span-2'}>
            <Divider orientation={"center"} className={'font-normal'}>Descargas</Divider>

            <Form.List name={"unloadsInfo"} initialValue={[{ storeId: '', endDatetime: '' }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Col key={key} className={'grid grid-cols-11 gap-x-2'}>
                      <FormItemComponent name={[name, 'storeId']}
                                         label={'Tienda'}
                                         required={true}
                                         className={'col-span-5'}
                                         child={
                                           <SelectComponent list={stores} />
                                         }
                      />
                      <FormItemComponent name={[name, 'dateTime']}
                                         label={'Fecha y hora'}
                                         required={true}
                                         className={'col-span-5'}
                                         child={
                                           <DatePicker showTime
                                                       className={'w-full h-9 rounded-md border border-grey-dkt-300'}
                                                       format={DATETIME_FORMAT} />
                                         }
                      />
                      {fields.length > 1 &&
                        <Col className={'col-span-1 grid'}>
                          <Tooltip title={'Quitar descarga'}>
                            <DeleteOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                            onClick={() => remove(name)} />
                          </Tooltip>
                        </Col>
                      }
                    </Col>
                  ))}
                  <Form.Item>
                    <ButtonComponent icon={<PlusOutlined />}
                                     text={'Agregar descarga'}
                                     className={'ant-btn-primary'}
                                     onClick={() => add()} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PlanningFormModal;
