import React, { useEffect, useState, useCallback } from 'react';
import { Row, Select, Calendar, Space, Col } from 'antd';
import moment from 'moment';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

// Store
import { useAppDispatch } from "../../store/store";
import * as plannerSelectors from "../../store/redux/selectors/plannerSelectors";
import { getParams } from "../../store/redux/actions/plannerActions";

// Types
import { PlannerType } from "../../types/plannerTypes";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";

dayjs.locale('es');

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, index) => index + start);

type PropsType = {
  onToggleModal: (visible: boolean, plannerToEdit?: PlannerType) => void;
};

const CalendarComponent = ({ onToggleModal }: PropsType) => {
  const dispatch = useAppDispatch();
  const [events, setEvents] = useState<any[]>([]);
  const [highlightedEventId, setHighlightedEventId] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().month());
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());

  const stores = useSelector(plannerSelectors.plannerParamsSelector)?.stores;
  const planner = useSelector(plannerSelectors.allPlannerSelector);

  useEffect(() => {
    dispatch(getParams(selectedMonth + 1, selectedYear));
  }, [selectedMonth, selectedYear, dispatch]);

  useEffect(() => {
    const newEvents = planner.flatMap(element => [
      {
        id: element.plannerId,
        dateTime: element.startDatetime,
        color: element.hexColor,
        withReturn: element.withReturn,
        isUnload: false,
        store: ''
      },
      ...element.unloadsInfo.map(unload => ({
        id: element.plannerId,
        dateTime: unload.endDatetime,
        color: element.hexColor,
        isUnload: true,
        store: stores?.find(store => store.id === unload.storeId)?.value || '',
      }))
    ]);

    setEvents(newEvents);
  }, [planner, stores]);

  const handleFormModal = useCallback((visible: boolean, plannerId?: string) => {
      const plannerToEdit = planner.find(p => p.plannerId === plannerId);
      onToggleModal(visible, plannerToEdit);
    },
    [planner, onToggleModal]
  );

  const getHeaderRender = useCallback(({ value, onChange }: any) => {
    const handleChange = (type: 'month' | 'year', newValue: number) => {
      const now = value.clone()[type](newValue);
      onChange(now);
      type === 'month' ? setSelectedMonth(newValue) : setSelectedYear(newValue);
    };

    const renderSelect = (type: 'month' | 'year', range: number[]) => (
      <Select size={"middle"}
              dropdownMatchSelectWidth={false}
              value={type === 'month' ? selectedMonth : selectedYear}
              onChange={newValue => handleChange(type, newValue)}
      >
        {range.map(number => (
          <Select.Option key={number} value={number}>
            {type === 'month' ? dayjs().month(number).format('MMMM') : number}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <Row className={"grid grid-cols-2 mb-5"}>
        <Col>
          {renderSelect('month', range(0, 12))}
          {renderSelect('year', range(selectedYear - 10, selectedYear + 10))}
        </Col>
        <Col className={"text-right"}>
          <ButtonComponent text={"Agregar"}
                           icon={<PlusOutlined />}
                           className={"ant-btn-primary"}
                           onClick={() => handleFormModal(true)}
          />
        </Col>
      </Row>
    );
  }, [selectedMonth, selectedYear, handleFormModal]);

  const dateCellRender = useCallback(
    (value: moment.Moment) => {
      const day = moment(value).date();
      const month = moment(value).month();

      const list = events
        .filter(event => moment(event.dateTime).date() === day && moment(event.dateTime).month() === month)
        .sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));

      return (
        <Space direction={"vertical"} size={"small"} className={"min-w-full"}>
          {list.map(item => {
            const time = moment(item.dateTime).format('HH:mm');

            return (
              <p key={item.id + item.dateTime}
                 style={{ backgroundColor: item.color }}
                 onMouseOver={() => setHighlightedEventId(item.id)}
                 onMouseLeave={() => setHighlightedEventId('')}
                 className={`w-full whitespace-nowrap px-[6px] py-[2px] rounded-lg text-xs text-grey-dkt-900 border 
                   border-grey-dkt-300 ${item.id === highlightedEventId && 'font-semibold border-blue-dkt-500 shadow-sm shadow-grey-dkt-600'}
                 `}
                 onClick={() => handleFormModal(true, item.id)}
              >
                {`${time}: ${item.isUnload ? 'Descarga' : 'Carga'}`}
                <br />
                {`${item.isUnload ? item.store : `[${item.withReturn ? 'Con' : 'Sin'} retorno]`}`}
              </p>
            );
          })}
        </Space>
      );
    },
    [events, highlightedEventId, handleFormModal]
  );

  return (
    <Calendar mode={"month"}
              dateCellRender={dateCellRender}
              headerRender={getHeaderRender}
              className={"[&>div>div>div>table>tbody>tr>td>div>div.ant-picker-calendar-date-content]:py-1"}
              onPanelChange={date => {
                setSelectedMonth(date.month());
                setSelectedYear(date.year());
              }}
    />
  );
};

export default CalendarComponent;
