import React, {useState} from 'react';
import {Row, Col} from 'antd';

// Components
import TitleComponent from '../../subComponents/TitleComponent';
import StoreMapsTable from './StoreMapsTable';
import StoreMapsFormModal from "./StoreMapsFormModal";

// Types
import {StoreMapType} from "../../../types/storeMapTypes";


const StoreMaps = () => {
  const [storeMapToEdit, setStoreMapToEdit] = useState<StoreMapType | undefined>(undefined)
  const [formModalVisible, setFormModalVisible] = useState(false);

  const handleModalVisible = (visible: boolean, storeMapToEdit?: StoreMapType) => {
    setFormModalVisible(visible);
    setStoreMapToEdit(storeMapToEdit);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Tiendas'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <StoreMapsTable onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <StoreMapsFormModal isEditing={!!storeMapToEdit}
                            storeMapToEdit={storeMapToEdit}
                            onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default StoreMaps;