// Libraries
import React, {FunctionComponent} from 'react';
import {Col, Row, Form, Space, DatePicker} from 'antd';
import PropTypes from 'prop-types';
import {DeleteOutlined, SearchOutlined} from '@ant-design/icons';

// Types
import {FilterParametersType, FilterType} from '../../types/sharedTypes';

// Components and Subcomponents
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import ButtonComponent from '../subComponents/ButtonComponent';

// Utils
import {DATE_FORMAT} from "../../utils/constants";

const { RangePicker } = DatePicker;


type FiltersComponentType = {
  filterParameters: FilterParametersType[];
  onChangeFilters: (filters: FilterType) => void;
}
const FiltersComponent: FunctionComponent<FiltersComponentType> = ({
    filterParameters,
    onChangeFilters
  }) => {
  const [form] = Form.useForm();

  const onSubmit = (values: FilterType, resetForm = false) => {
    if(resetForm){
      form.resetFields();
    }
    onChangeFilters(values);
  };

  return (
    <Row gutter={[8, 8]}>
      <Col lg={24} md={24} xs={24}>
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onSubmit} className={'contents'}>
          {filterParameters.map(filter =>
            <Col lg={7} md={7} xs={24} key={filter.fieldName} className={'w-full inline-block'}>
              {filter.type === 'rangeDate'
                ? <FormItemComponent name={filter.fieldName} label={''} required={false}
                                     child={
                                       <RangePicker placeholder={filter.placeHolder as [string, string]}
                                                    format={DATE_FORMAT}
                                                    allowClear={false} />
                                     } />
                : <FormItemComponent name={filter.fieldName} label={''} required={false}
                                     child={
                                       <InputComponent className={'w-full'} placeHolder={filter.placeHolder as string} />
                                     } />
              }
            </Col>
          )}
          <Col lg={3} md={3} xs={24} className={'w-full inline-grid'}>
            <Space>
              <ButtonComponent text={''} icon={<SearchOutlined />} htmlType={'submit'} />

              <ButtonComponent text={''} icon={<DeleteOutlined />}
                               onClick={() => onSubmit({}, true)} />
            </Space>
          </Col>
        </Form>
      </Col>
    </Row>
  );
}

FiltersComponent.propTypes = {
  filterParameters: PropTypes.any,
  onChangeFilters: PropTypes.func.isRequired,
};
FiltersComponent.defaultProps = {
  filterParameters: [],
  onChangeFilters: () => {
    return;
  }
};

export default FiltersComponent;