import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {StoreMapType} from "../types/storeMapTypes";


function getAll(data: PaginationType) {
  return request({
    url: 'storeMap/getAll',
    method: 'GET',
    params: data
  });
}
function update(storeMapId: string, storeMap: StoreMapType) {
  return request({
    url: `storeMap/update/${storeMapId}`,
    method: 'PATCH',
    data: storeMap
  });
}

export default {
  getAll,
  update,
}