import {routes} from "./routes";

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const FULL_DATETIME_FORMAT = 'D [de] MMMM [del] YYYY [a las] HH:mm';

export const ROLES = {
  freightAdmin: {
    name: 'freightAdmin',
    allowedModules: [
      {
        name: routes.remissions,
        permissions: { canUpload: true, canDownload: true }
      },
      {
        name: routes.ctn,
        permissions: { canAdd: true, canDelete: true, canUpload: true, canDownload: true }
      },
      {
        name: routes.tariff,
        permissions: { canUpload: true, canDownload: true }
      },
      {
        name: routes.thresholds,
        permissions: { canUpload: true, canDownload: true }
      },
      {
        name: routes.inbound,
        permissions: { canUpload: true, canDownload: true }
      },
    ]
  },
  freightUser: {
    name: 'freightUser',
    allowedModules: [
      {
        name: routes.remissions,
        permissions: { canUpload: false, canDownload: false }
      },
      {
        name: routes.ctn,
        permissions: { canAdd: false, canDelete: false, canUpload: false, canDownload: false }
      },
      {
        name: routes.tariff,
        permissions: { canUpload: false, canDownload: false }
      },
      {
        name: routes.thresholds,
        permissions: { canUpload: false, canDownload: false }
      },
      {
        name: routes.inbound,
        permissions: { canUpload: false, canDownload: false }
      },
    ]
  },
  enviateAdmin: {
    name: 'enviateAdmin',
    allowedModules: [
      { name: routes.shipping, permissions: null },
      { name: routes.alliances, permissions: null },
      { name: routes.stores, permissions: null },
      { name: routes.packageSize, permissions: null },
      { name: routes.orders, permissions: null },
      { name: routes.locations, permissions: null },
    ]
  },
  enviateUser: {
    name: 'enviateUser',
    allowedModules: [
      { name: routes.shipping, permissions: null },
    ]
  },
  admin: {
    name: 'admin',
    allowedModules: [
      {
        name: routes.remissions,
        permissions: { canUpload: true, canDownload: true }
      },
      {
        name: routes.ctn,
        permissions: { canAdd: true, canDelete: true, canUpload: true, canDownload: true }
      },
      {
        name: routes.tariff,
        permissions: { canUpload: true, canDownload: true }
      },
      {
        name: routes.thresholds,
        permissions: { canUpload: true, canDownload: true }
      },
      {
        name: routes.inbound,
        permissions: { canUpload: true, canDownload: true }
      },
      { name: routes.shipping, permissions: null },
      { name: routes.alliances, permissions: null },
      { name: routes.stores, permissions: null },
      { name: routes.packageSize, permissions: null },
      { name: routes.orders, permissions: null },
      { name: routes.locations, permissions: null },
      { name: routes.planning, permissions: null },
      { name: routes.users, permissions: null },
      { name: routes.suppliers, permissions: null },
      { name: routes.carriers, permissions: null },
      { name: routes.storeMaps, permissions: null },
    ]
  },
  carrier: {
    name: 'carrier',
    allowedModules: [
      { name: routes.transport, permissions: null },
    ]
  },
  supplier: {
    name: 'supplier',
    allowedModules: [
      { name: routes.transport, permissions: null },
    ]
  },
};

export const MODAL_ACTIONS = {
  edit: 'edit',
  duplicate: 'duplicate',
  create: 'create',
};
