// Libraries
import React, {FunctionComponent, ReactElement} from 'react';
import {Form} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { Rule } from 'antd/lib/form';

// Utils
import {currencyFormat, currencyToNumber} from '../../utils/functions';

const { Item } = Form;


type FormItemComponentType = {
  label?: string;
  name?: string | (string | number)[];
  valuePropName?: string;
  hasFeedback?: boolean;
  required: boolean;
  withValueValidator?: boolean;
  className?: string;
  newRules?:  Rule[];
  dependencies?: NamePath[];
  child: ReactElement
};
const FormItemComponent: FunctionComponent<FormItemComponentType> = (
  {
    label, name, valuePropName, hasFeedback, required,
    withValueValidator, className, newRules, dependencies, child,
  }) => {
  const rules: Array<object> = [{ required: required, message: 'Campo requerido' }];
  newRules?.map(rule => rules.push(rule));

  return (
    withValueValidator
    ?
      <Item label={label} name={name} valuePropName={valuePropName}
            className={'mb-3 [&>div>div]:pb-1 [&>div>div>label]:text-sm [&>div>div>label]:font-medium ' +
              '[&>div>div>label]:text-grey-dkt-400 ' + className
            }
            rules={rules}
            getValueFromEvent={(e) => {
              const value = currencyToNumber(e.target.value);

              return isNaN(value) ? 0 : currencyFormat(value.toFixed(2).toString());
            }}>
        {child}
      </Item>
      :
      <Item label={label}
            name={name}
            valuePropName={valuePropName}
            className={'mb-3 [&>div>div]:pb-1 [&>div>div>label]:text-sm [&>div>div>label]:font-medium ' +
              '[&>div>div>label]:text-grey-dkt-400 ' + className
            }
            dependencies={dependencies}
            hasFeedback={hasFeedback}
            rules={rules}>
        {child}
      </Item>
  );
}

FormItemComponent.defaultProps = {
  label: undefined,
  name: undefined,
  valuePropName: 'value',
  required: true,
  withValueValidator: false,
  className: '',
  child: <></>,
};

export default FormItemComponent;