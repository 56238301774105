// Libraries
import React from 'react';
import {Row, Form, Modal, Switch} from 'antd';
import {useAppDispatch} from '../../../store/store';

// Store
import { create, update } from '../../../store/redux/actions/carrierActions';

// Types
import {CarrierType} from "../../../types/carrierTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';


type PropsType = {
  isEditing?: boolean;
  carrierToEdit?: CarrierType;
  onToggleModal: (visible: boolean) => void;
};
const CarriersFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isEditing, carrierToEdit, onToggleModal } = props;

  const handleSubmit = (carrier: CarrierType) => {
    if(isEditing){
      dispatch(update(carrier, carrierToEdit?.carrierId ?? ''));
    } else {
      carrier.isActive = true;
      dispatch(create(carrier));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} transportista`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={carrierToEdit ?? {}}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          {isEditing &&
            <FormItemComponent name={'isActive'}
                               className={'col-span-2'}
                               label={''}
                               required={false}
                               valuePropName={'checked'}
                               child={<Switch checkedChildren={"Activa"}
                                              unCheckedChildren={"Inactiva"} />}
            />
          }

          <FormItemComponent name={'name'}
                             label={'Nombre'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'nit'}
                             label={'Nit'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'address'}
                             label={'Dirección'}
                             required={true}
                             child={<InputComponent />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default CarriersFormModal;
