// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import storeMapServices from '../../../api/storeMapServices';

// Types
import {storeMapTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {StoreMapType} from "../../../types/storeMapTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeMapServices.getAll(data)
    .then((response) => {
      dispatch({
        type: storeMapTypes.GET_ALL_STORE_MAPS,
        storeMapsResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (storeMapId: string, storeMap: StoreMapType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeMapServices.update(storeMapId, storeMap)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
